.footer{
    background: linear-gradient(90deg, rgba(222,0,0,1) 0%, rgba(33,25,25,1) 0%);
    &__menu{
        display: flex;
        flex-direction: column;
    }
    &__menu-list{
        list-style-type:none;
        margin:0 auto;
        padding:20px 0 0;
    }
    &__menu-item{
        color:#ffff;
        padding: 5px 0;
        font-size: 15px;
        font-weight: lighter;
        cursor: pointer;
    }
    &__menu-link:active{
        color:#3fa9f5;
    }
    &__menu-link:hover{
        color:#0b72bc;
    }
    &__menu-link{
        text-decoration: none;
        color:#ffff;
    }
    &__social-media{
        display: flex;
        margin: 0 auto;
        justify-content: center;
        padding-bottom:5px;
        border-bottom: 1px solid #cccccc;
    }
    &__social-media-icon{
        color:#ffff;
        width:35px;
        height: 25px;
        padding: 5px 8px;
        margin:0 5px;
    }
    &__contact{
        text-align: center;
        padding-bottom: 10px;
    }
    &__contact-info{
        color:#706767;
        font-size: 13px;
    }
    &__conduit-code{
        cursor: pointer;
    }
}
@media(min-width:1024px){
    .footer{
        padding: 20px 0 10px;
        border-top: 1px solid #211919;
        
        &__menu{
            width:60%;
        }
        &__menu-social{
            display: flex;
        }
        &__menu-list{
            display: flex;
            justify-content: flex-start;
            width:80%;
            margin:0 0 20px 15%;
        }
        &__social-media{
            border:none;
            padding:20px 0 0;
        }
        &__social-media-icon{
            padding:0 15px;
        }
        &__menu-item{
            padding:0 30px;
        }
        &__contact{
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            padding:10px 10%;
        }
        &__contact-box{
            display: flex;
            flex-direction: row-reverse;
        }
        &__contact-info{
            font-size: 15px;
            padding:0 15px;
        }
    }
}
