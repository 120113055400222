.updates{
    background-color: #f9f9f9;
    &__first-section{
        height:auto;
    }
    &__sections{
        display: flex;
        width:70%;
    }
    &__sections-name:hover{
       color: #3fa9f5;
       cursor: pointer;
    }
    &__img{
        width:100%;
        height:auto;
        border:0.1px solid #cccccc;
    }
    &__section-title{
        margin:0px 3px;
        font-weight: bold;
    }
    &__section-date{
        margin:0 3px;
        font-weight: bold;
    }
    &__section-description{
        margin:3px 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &__info{
        width:80%;
        margin:0 auto;
        background-color: #ffff;
        position: relative;
        bottom: 60px;
        padding:15px 10px 10px;
        border:0.1px solid #cccccc;
    }
    &__title-wrapper{
        position: relative;
    }
    &__button{
        position: absolute;
        bottom: 80px;
        left: 25px;
        width: auto;
    }

    &__button-writing {
      margin: 0;
      font-size: 14px;
      padding:2px 5px;
      font-weight: bold;
      letter-spacing: 1px;
      color: #ffffff;
    }

    &__loading-more{
        text-align: center;
        font-weight: bold;
        color: #211919;
        padding-bottom: 30px;
        font-size:12px;
        cursor: pointer;
    }
    &__link{
        text-decoration: none;
        cursor: pointer;
    }
    &__see-more{
        font-weight: bold;
        font-size: 15px;
        color: #3fa9f5;
        margin:0;
    }
    &__wrapper{
        box-sizing: border-box;
    }
}
@media(min-width:768px){
    .updates{
        &__wrapper{
            padding:40px 5% 0;
        }
        &__sections{
            padding-bottom: 20px;
            width:50%;
        }
        &__button{
            left: 60px;
        }
    }
}
@media(min-width:1024px){
    .updates{
        &__sections{
            justify-content: flex-start;
            padding-left: 10%;
        }
        &__sections-name{
            font-size: 18px;
            width:auto;
        }
        &__wrapper{
            padding:40px 10% 30px;
            display: flex;
            flex-wrap: wrap;
            width:100%;
        }
        &__wrapper-opposite{
            padding:80px 10% 30px 0;
            width:50%;
        }
        &__img{
            height:300px;
            object-fit: cover;
        }
        &__info{
            margin:0;
            padding:10px 20px;
            box-sizing: border-box;
            width:100%;
            bottom:0;
            min-height: 140px;
        }
        &__button{
            bottom: 10%;
            left:2%;
        }
        &__individual{
           width:50%;
           position: relative;
        }
        &__individual-right{
            width:100%;
        }
        &__grow{
            width:100%;
        }
        &__section-title{
         font-size:20px;
        }
        &__section-date{
           font-size: 16px;
        }
        &__section-description{
           font-size: 18px;
        }
        &__container{
            display: flex;
        }
    }
}
