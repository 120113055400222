h1, h2, h3, h4, h5, li,p{
    font-family: NeoSansStd;
}
h1{    
    font-size: 19px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;
    letter-spacing: normal;
}
h2{
    font-size: 17px;
    font-weight: lighter;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.31;
    letter-spacing: normal;
}
h3{
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.45;
    letter-spacing: normal;
    font-size:15px;
}
h4{
    font-size: 16px;
    font-weight: lighter;
}
h5{
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.5px;
}
p{
    font-size: 12px;
    letter-spacing: 0.2;
}
@media(min-width:768px){
    h1{
        font-size:20px;
    }
}
@media(min-width:1024px){
    h1{
        font-size:32px;
        line-height: 1.38;
    }
    h2{
        font-size: 28px;
    }
    h3{
        font-size:22px;
    }
    h4{
        font-size:15px;
    }
    h5{
        font-size:12px;
    }
}