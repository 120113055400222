.article{
    background-image: linear-gradient(to top, #f9f9f9, #ffffff);
    &__change-color{
        color: #211919;
    }
    &__logo{
        background-color: transparent;
    }
    &__title{
        font-size: 24px;
        color: #404040;
    }
    &__first-section{
        height:auto;
        background-image: linear-gradient(to top, #f9f9f9, #ffffff);
    }
    &__date-box{
        display: flex;
        padding: 0 0 30px 20px;
    }
    &__date{
        margin:0;
        padding-right: 10px;
        font-weight: bold;
        font-size: 12px;
    }
    &__article-button{
        width:60px;
        height:18px;
    }
    &__button-writing{
        font-size: 10px;
    }
    &__article-img{
        width: 100%;
        height:320px;
        object-fit:none;
    }
    &__info{
        padding:0 20px 10px;
    }
    &__description{
        font-size: 16px;
        color: #4d4d4d;
    }
    &__article-img-second{
        height:auto;
        object-fit: cover;
    }
    &__section-question{
        font-weight: bold;
        margin:5px 0 25px;
        font-size: 12px;
    }
    &__section-subtitle{
        margin-bottom: 0;
    }
    &__resume{
        font-weight: bold;
        margin-bottom: 5px;
    }
    &__other{
        padding-bottom: 30px;
    }
    &__other-list{
        list-style-type: none;
        padding:0;
        margin:0;
    }
    &__other-item{
        color:#3fa9f5;
        padding:2px 0;
    }
    &__more-updates{
        background-color: transparent;
    }
    &__menu{
        color:#211919;
    }

    &__charts-container {
      box-sizing: border-box;
      width: 100%;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
    }
}
@media(min-width:768px){
    .article{
        &__title{
            font-size: 26px;
            padding-top: 20px;
        }
        &__date-box{
            padding: 0 0 60px 60px;
        }
        &__info{
            padding:0 50px 10px;
        }
        &__work-wrapper{
            display: flex;
            padding-top: 0;
        }
    }
}

@media(min-width:1024px){
    .article{
        &__title{
            font-size: 32px;
        }
        &__date-box{
            padding:20px 0 60px 10%;
        }
        &__date{
            font-size: 15px;
        }
        &__article-button{
            height:20px;
        }
        &__button-writing{
            font-size: 12px;
        }
        &__article-img{
            height:400px;
        }
        &__info{
            width:60%;
            padding:30px 10% 20px;
        }
        &__description{
            font-size: 22px;
        }
        &__article-img-second{
            width: 950px;
            height: 600px;
            padding-left: 10%;
        }
        &__section-question{
            margin:0 0 25px;
        }
        &__other-item{
           font-size: 18px;
        }
        &__other{
            padding-top: 20px;
        }
        &__other-list{
            margin-top: 10px;
        }
        &__work-img{
            height:300px;
        }
        &__work-info{
            width:93%;
        }
        &__work-wrapper{
            padding:0 10% 20px;
        }
        &__individual{
            width:50%;
        }

        &__charts-container {
          margin-left: 10%;
          width: 80%;
        }
    }
}
