.work{
    background-color: #f9f9f9;
    &__work-page-img{
        width: 100%;
        height:auto;
    }
    &__info{
        background-color:#ffff;;
        width: 80%;
        padding: 10px;
        position: relative;
        bottom: 35px;
        margin: 0 auto;
        border:0.1px solid #cccccc;
    }
    &__section-title{
        margin:0 0 5px;
        font-size: 19px;
    }
    &__section-description{
        margin:0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &__see-more{
        margin: 10px 0 0;
        font-weight: bold;
        font-size: 15px;
        color: #3fa9f5;
        cursor: pointer;
    }
}

@media(min-width:768px){
    .work{
      &__wrapper{
          padding:20px 30px;
      }
    }
}
@media(min-width:1024px){
    .work{
        &__wrapper{
            padding: 70px 10%;
        }

        &__box {
          position: relative;
        }

        &__wrapper-two{
            display: flex;
        }

        &__work-page-img{
            height:580px;
            object-fit: cover;
            padding-bottom: 80px;
        }
        &__info{
            position: absolute;
            width: 50%;
            left: 0;
            padding:20px;
        }
        &__info-second{
            width:100%;
            right:0;
        }
        &__section-title{
            margin:0 0 5px;
            font-size: 21px;
            font-weight: 600;

        }
        &__section-description{
            margin:0;
            font-size: 18px;
            padding:0 10px 0 0;
        }

        .full-width {
          width: 100%;
        }
    }
}
