body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  z-index: 1;
  height: 100%;
  position: relative;
    &.has-active-menu {
      .body_overlay {
        visibility: visible;
        transition: visibility 0s .3s;
      }
    }
  
    .body_overlay {
      position: fixed;
      background: #ffff;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      overflow-x: hidden;
      overflow-y: auto;
      outline: 0;
      opacity: .75;
      filter: alpha(opacity=50);
      visibility: hidden;
      transition: none;
      z-index: 10;
    }
  }