@import './typography.scss';
@import './buttons.scss';

.standard-first-section{
    background: linear-gradient(90deg, rgba(222,0,0,1) 0%, rgba(33,25,25,1) 0%);
    height:230px;
}
.standard-intro{
    width: 100%;
}
.standard-title{
    color:#ffff;
    margin-left: 20px;
}
.standard-subtitle{
    margin:15px 20px;
    color:#ffff;
    width:70%;
}
.standard-section-title{
    font-weight: bold;
}
.standard-section-description{
    margin:10px 0;
    text-align: justify;
}
.standard-section-description-first{
    font-size: 15px;
    text-align:left;
}
.standard-invitation{
    display: flex;
    flex-direction: column;
    background-color: #ffff;
    padding-bottom: 15px;
}
.standard-invite{
    color:black;
    text-align: center;
}
.standard-work-button{
    width: 150px;
    height: 45px;
    margin:10px auto;
    background-color: red;
    border:none;
    display: flex;
}
.standard-button-writing{
    color:#ffff;
    text-align: center;
    margin:auto;
    font-weight: bold;
    overflow: hidden;
}
.standard-button-writing:hover{
    transform: scale(1.2);
    transition: transform .5s ease;
}
.standard-link{
    text-decoration: none;
}
.standard-link-info{
    color:#3fa9f5;
    font-size: 12px;
    padding-left: 20px;
}
@media(min-width:768px){
    .standard-first-section{
        border: none;
    }
    .standard-title{
        margin-left: 60px;
    }
    .standard-subtitle{
        margin:20px 0 0 60px;
        width:50%;
        text-align: left;
    }
    .standard-invite{
        width:100%;
        text-align: center;
    }
}

@media(min-width:1024px){
    .standard-title{
        margin:100px 0 0 10%;
    }
    .standard-subtitle{
        margin:20px 0 0 10%;
        width:45%;
    }
    .standard-first-section{
        height:400px;
    }
    .standard-section-title{
        font-size: 30px;
        margin-bottom: 10px;
    }
    .standard-section-box{
        display: flex;
        justify-content: space-between;
    }
    .standard-section-description{
        font-size: 20px;
        width:100%;
        color:#211919;
    }
    .standard-section-description-first{
        font-size: 20px;
        width:50%;
        margin-right: 50px;
    }
    .standard-title-box{
        width:25%;
    }
    .standard-invitation{
        flex-direction: row;
        padding:50px 0 40px;
        width:70%;
        margin:0 auto;
        font-weight: lighter;
    }
    .standard-invite{
        width:70%;
        margin:0;
    }
    .standard-work-button{
        width:180px;
        height:40px;
    }
    .standard-button-writing{
       font-size: 15px;
    }
}
