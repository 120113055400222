.homepage{

    &__first-section{
        background: linear-gradient(90deg, rgba(222,0,0,1) 0%, rgba(33,25,25,1) 0%);
        height:100%;
    }
    &__intro{
        width:100%;
        height:auto;
        display: flex;
        flex-direction: column;
    }
    &__gif{
        position: relative;
        background: linear-gradient(90deg, rgba(222,0,0,1) 0%, rgba(33,25,25,1) 0%);
    }
    &__description{
        width: 64%;
        text-align: center;
        margin:30px auto 0;
        color:#ffff;
    }
    &__description-second{
        margin-top: 10px;
    }

    &__service-section{
        background-color:#f2f2f2;
        background-size: 89% 100%;
        background-repeat: repeat;
        background-image: linear-gradient(to right, #cccccc 1px, transparent 1px);
        background-position: 40.5%;
    }
    &__services-box{
        margin:0 30px;
    }
    &__services-title{
        color:black;
        margin: 0;
        padding-top:15px;
    }
    &__services-subtitle{
        margin: 10px 0;
        font-weight: lighter;
        font-size: 18px;
    }
    &__view-more{
        margin:0;
        font-weight: bold;
        font-size: 15px;
        color: #3fa9f5;
        cursor: pointer;
    }
    &__view-more:hover{
        color:#0b72bc;
    }
    &__services-specific-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top:20px;
    }
    &__services-specific{
        padding:15px;
        width:80%;
        border-top:0.1px solid #cccccc;
        border-bottom:0.1px solid #cccccc;
        background-color: #ffff;
        margin-bottom: 20px;
    }
    &__services-specific-title{
        margin:0 20px;
        font-size:20px;
    }
    &__services-specific-info{
        margin:5px 20px 10px;
        font-weight: lighter;
        font-size: 18px;
    }
    &__services-view-more{
       margin:0 20px;
    }
    &__services-img{
        width:60px;
        height:auto;
        display: flex;
        margin:10px 20px;
    }
    &__work-section{
        height: 710px;
    }
    &__link{
        text-decoration: none;
        cursor: pointer;
    }
}
@media(min-width:768px){
    .homepage{
    &__description{
        margin:30px 0 0 10%;
        width:35%;
        text-align: left;
    }
    &__services-box{
        margin:0 10%;
    }
    &__services-subtitle{
        margin-bottom:5px;
    }
    &__services-specific-wrapper{
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: initial;
    }
    &__services-specific{
        width:40%;
        border:0.1px solid #cccccc;
        margin-bottom: 15px;
    }

}
}
@media(min-width:1024px){
    .homepage{
        &__description{
            width: 25%;
            text-align: left;
            margin:10% 0 0 10%;
            color:#ffff;
        }
        &__description-second{
            margin-top: 10px;
        }
        &__first-section{
            height:600px;
        }
        &__gif{
            position: relative;
            bottom:240px;
            left:200px;
            height:480px !important;
        }
        &__services-box{
            margin:0 0 0 10%;
            width:40%;
        }
        &__services-subtitle{
            font-size:23px;
            width:100%;
            margin-bottom: 10px;
            letter-spacing: -0.9px;
        }
        &__services-specific-title{
            font-size:22px;
            text-align: left;
        }
        &__services-specific-info{
            text-align: left;
            font-size: 19px;
        }
        &__services-view-more{
            text-align: left;
            font-size: 16px;
        }
        &__service-section{
            display: flex;
            justify-content: center;
            background-size: 29.3% 100%;
            background-repeat: repeat;
            background-image: linear-gradient(to right, #cccccc 1px, transparent 1px);
            background-position: 14%;

        }
        &__services-specific-wrapper{
           width:100%;
           margin:50px 0 0 5%;
           flex-wrap:wrap;
           justify-content: initial;
           padding-bottom: 30px;
        }
        &__services-specific{
            width:40%;
            padding-left:20px;
            margin:0;
        }
        &__intro{
            display: flex;
            flex-direction: row;
            position: relative;
            width:100%;
        }
        &__introduction-box{
            width:100%;
        }
        &__video, &__video-default-image{
            width:75%;
            position: absolute;
            right:0;
            top:250px;
        }
    }
}
