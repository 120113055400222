.project{
    &__first-section{
        height:280px;
        background-image: linear-gradient(to top, #f9f9f9, #ffffff);
    }
    &__middle-section{
        background: linear-gradient(90deg, #de0000 0%, #211919 0%);
    }
    &__middle-section-img{
        width:100%;
        height:auto;
    }
    &__details-box{
        padding:20px;
        position: relative;
    }
    &__section-title{
        margin: 0;
        color:#ffff;
        font-weight: bold;
        padding: 20px 0 0;
    }
    &__section-description{
        color: #ffff;
        margin:20px 0 0;
        font-size: 18px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: 2;
        letter-spacing: normal;
    }

    &__change-color{
        color: #211919;
    }

    &__lower-section{
        background-image: linear-gradient(to top, #f9f9f9, #ffffff);
    }
    &__lower-section-img{
        opacity:0.7;
    }
    &__related-projects{
        padding:20px 20px 0;
        letter-spacing: 1.2px;
        margin: 0;
    }
    &__link-info{
        font-weight: bold;
        margin:0;
    }
    &__website-link-button{
        border-image-source: linear-gradient(to bottom, #ff0000, #b30000);
        width:100%;
        height:40px;
        margin-top: 20px;
        background-color: #ff0000;
        cursor: pointer;
    }
    &__link-project{
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 1px;
        text-align: center;
        color: #ffffff;
        margin:5px 0 0;
      }
    }


@media(min-width:768px){
    .project{
        &__first-section{
            height:240px;
        }
        &__link-info{
            padding-left: 60px;
        }
        &__details-box{
            padding:10px 60px;
        }
        &__related-projects{
            padding:20px 60px 0;
            font-size: 20px;
        }
        &__website-link-button{
            width:50%;
            margin:20px 0;
        }
    }
}
@media(min-width:1024px){
    .project{
        &__first-section{
            height:400px;
        }
        &__link-info{
            padding:20px 10% 0;
            font-size: 16px;
        }
        &__middle-section-img{
            height:550px;
            object-fit: cover;
        }
        &__details-box{
            padding:30px 10% 40px;
            width:70%;
        }
        &__section-title{
            letter-spacing: 2.2px;
        }
        &__title{
            margin-top: 50px;
        }
        &__subtitle{
            font-size: 26px;
            width:50%;
            margin-bottom: 20px;
        }
        &__section-description{
            font-size: 22px;
            width:80%;
        }
        &__more-projects{
            width:80%;
            margin:0 auto;
        }
        &__work-img{
            height:500px
        }
        &__related-projects{
            padding: 20px 10%;
            font-size: 28px;
        }
        &__individual{
            width:50%;
            position: relative;
        }
        &__website-link-button{
            width:180px;
            margin-top: 30px;
        }
        &__link-project{
            font-size: 16px;
            margin: 0;
        }
    }
}
