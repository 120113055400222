.contact{
    &__container{
        background: linear-gradient(90deg, rgba(222,0,0,1) 0%, rgba(33,25,25,1) 0%);
        height:auto;
        margin:-1px;
    }
    &__first-section{
        height:205px;
    }
    &__form{
        display: flex;
        flex-direction: column;
        margin:0 auto;
        padding:0 30px 50px 20px;
        justify-content: center;
    }

    &__input, &__text-area{
        border: none;
        border-bottom: 1px solid #888;
        background: transparent;
        margin: 10px;
        padding: 8px 0;
        width:100%;
        color: white!important;
        font-size: 16px;
        font-family: NeoSansStd;
    }

    &__input::placeholder, &__text-area::placeholder{
        color:#ffffff;
        font-family: NeoSansStd;
        font-size: 16px;
    }
    &__submit-button{
        width:100%;
        border:none;
        margin: 20px 0 0 10px;

        &:focus {
          outline: none;
        }
    }
}
@media(min-width:768px){
    .contact{
        &__form{
            padding:0 80px 30px;
        }
        &__submit-button{
            width:60%;
            margin:25px auto 0;
        }
    }
}

@media(min-width:1024px){
    .contact{
        &__first-section{
            height:330px;
        }
        &__form{
            width:40%;
            margin:0;
            padding:0 10% 5%;
        }
        &__input::placeholder, &__text-area::placeholder {
            font-size: 18px;
            font-weight: 300;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.45;
            letter-spacing: normal;
            color: #ffffff;
        }

        &__submit-button{
            width:220px;
            height: 50px;
            margin: 10px 0 0;
        }
        &__input, &__text-area{
            font-size: 18px;
            margin: 30px 0;
            padding-left: 5px;
        }
    }
}
