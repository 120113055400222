.hamburger{
    &__icon{
        width:30px;
        margin-right: 20px;
        position: relative;
        top: 5px;
        cursor: pointer;
        color:red;
    }
    &__close-icon {
        display: none;
        position: absolute;
        width: 24px;
        right: 3%;
        top: 20px;
        z-index: 20;
    
        &.active {
          display: block;
        }
      }
    &__links {
        list-style: none;
        width: 55%;
        float: right;
        margin-right: 0;
        z-index: 20;
        clear: both;
        overflow: hidden;
        top: 0;
        right: 0;
        padding: 20px;
        height: 100%;
        margin-top: 0;
        background-color: #211919;
        border-right: solid 1px lightgrey;
        position: fixed;
        transform: translateX(100%);
        transition: transform .3s;
        overflow-y: auto;

    
        &.active {
          transform: translateX(0);
        }
    }
    &__list-item{
        font-family: NeoSansStd;
        font-size: 20px;
        line-height: 1.64;
        color:#ffff;
        padding:10px;
        border-bottom: 1px solid #cc0000;
    }
    &__link--menu{
        font-family: NeoSansStd;
        font-size: 22px;
        font-weight: bolder;
        margin: 10px 0 20px;
        color:#ffff;
        padding-left: 8px;
    }
    &__link{
        text-decoration: none;
        color:initial;
    }

    @media(min-width:1024px){
        &__icon{
            display: none;
        }
    }
}