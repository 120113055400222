.work-section{
    background-color:#1e0d0d;
    color:#ffff;
    height:auto;
    width:100%;
    display: block;
    &__box{
        margin-left:20px;
        padding:20px 0;
    }
    &__title{
        margin:0;

    }
    &__subtitle{
        margin: 10px 20px 10px 0;
    }
    &__left-section{
        width:100%;
    }
    &__img{
        width:100%;
        height:200px;
        object-fit: cover;
        object-position: left;
    }
    &__img-info{
        background-size:cover;
        display: flex;
        flex-direction: column-reverse;
        border:0.7px solid #cccccc;
        padding:0;
        position: relative;
    }

    &__details{
        display: flex;
        flex-direction: column;
        position: absolute;
        bottom:-15px;
        left: 5px;
        background-color:#f9f9f9;
        padding: 5px;
        border: 1px solid #d9d9d9;
        cursor: pointer;
        z-index: 10;
    }
    &__title-specific{
        font-size:16px;
        font-weight: bolder;
        margin:0;

    }
    &__subtitle-specific{
        font-size:15px;
        width:100%;
        margin:0 0 5px;
        line-height: normal;
    }
    &__updates{
        background-color: #f2f2f2;
    }
    &__updates-box{
        margin:0 20px 0px;
        padding-top: 25px;
    }
    &__updates-description{
        color:#000000;
    }
    &__updates-container{
        display: flex;
        flex-direction: column;
        margin:0 auto;
    }
    &__updates-individual{
        width:100%;
        margin:20px 0;
        border-top:1px solid #cccccc;
        background-color: #ffff;
    }
    &__updates-presentation-1{
        background-image:url('/images/updates-1.jpg');
    }
    &__updates-presentation-2{
        background-image:url('/images/updates-2.jpg');
    }
    &__updates-presentation-3{
        background-image:url('/images/updates-5.jpg');
    }
    &__updates-presentation-4{
        background-image:url('/images/updates-4.jpg');
    }
    &__updates-presentation{
        background-size: cover;
        width: 100%;
        height: 200px;
        position: relative;
    }
    &__updates-info{
        padding:10px 10px;
        border-top:1px solid #cccccc;
    }
    &__updates-date{
        font-weight: bold;
        margin:5px 0;
    }
    &__updates-button{
      position: absolute;
      bottom: 0;
      border:none;
    }
    &__updates-button-writing{
        color:#ffff;
        text-align: center;
        margin:0;
        font-weight: bold;
    }
    &__updates-info{
        padding:10px 10px 25px;
        border-top:1px solid #cccccc;
    }
    &__link{
        text-decoration: none;
        color:#3fa9f5;
        cursor: pointer;
    }
    &__updates-information{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 0;
    }
}
#see-more{
    color:#3fa9f5;
}
#project-title{
    color: #3fa9f5;
}

@media(min-width:768px){
    .work-section{
        display: flex;
        padding-bottom: 20px;
        &__box{
            width:70%;
            margin: 20px 40px 0px;
            padding-top:20px;
        }
        &__right-section{
            position: relative;
            top:90px;
            margin-right:20px;
        }
        &__img{
            height: 100%;
        }
        &__img-box{
            margin-left:20px;
        }
        &__img-info{
            width:360px;
            height:240px;
            border:1px solid #cccccc;
            margin:0;
        }
        &__updates{
            padding:30px 0 120px;
        }
        &__updates-box{
            margin:0 40px 0px;
            padding-top: 10px;
        }
        &__updates-info{
            padding:10px 10px;
            border-top:1px solid #cccccc;
        }
        &__updates-date{
            font-weight: bold;
            margin:5px 0;
        }
        &__updates-container{
            flex-direction:initial;
            justify-content: center;
            flex-wrap: wrap;
            margin-top: 20px;
        }
        &__updates-individual{
            width:48%;
            margin:0;
            border:0.7px solid #cccccc;
        }
        &__project-link{
            color:#ffff;
        }
    }
}

@media(min-width:1024px){
    .work-section{
        height:950px;
        &__box{
            width:60%;
            margin-left:20px;
            padding: 40px 0 25px;
        }
        &__right-section{
            position: relative;
            top:50px;
            margin-right:10%;
            width:50%;
        }
        &__left-section{
            margin-left:10%;
            width:50%;
        }
        &__img-box{
            margin-left:20px;
        }
        &__img-info{
            width: 100%;
            height: 440px;
        }
        &__details{
            padding:15px 20px;
        }
        &__title-specific{
            font-size:18px;

        }
        &__subtitle-specific{
            font-size:20px;
        }
        &__read-more{
            font-size:15px;
            padding-top: 3px;
        }
        &__updates{
            height:auto;
            padding: 20px 0 20px 10%;
            display: flex;
        }
        &__updates-box{
            width:25%;
        }
        &__updates-container{
            width:75%;
        }
        &__updates-individual{
            width:40%;
        }
        &__updates-button-writing{
            font-weight: light;
        }

    }
}
