.fzf{
    height:auto;
    &__wrapper{
        background-color: #211919;
        padding:50px 20px;
    }
    &__title{
        font-family: NeoSansStd;
        font-size: 32px;
        font-weight: 300;
        line-height: 1.38;
        margin: 30px auto;
        color: #ffffff;
        text-align: center;
      
    }
    &__redirect{
        font-family: NeoSansStd;
        font-size: 22px;
        font-weight: 300;
        line-height: 1.31;
        color: #ffffff;
        text-align: center;
    }
    &__error-img{
        width:100%;
        height:300px;
        object-fit: cover;
    }
    @media (min-width:1024px){
        &__wrapper{
            padding:50px 10% 100px;
        }
        &__error-img{
            height:550px;
            padding-top: 20px;
        }
        &__title, &__redirect{
            text-align: left;
            padding-left: 10%;
        }
        &__title{
            font-size: 36px;
            margin-top: 45px;
        }
        &__redirect{
            font-size: 28px;
        }
    }
}