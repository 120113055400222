.services{
    &__box{
        background-color: #f9f9f9;
        padding-bottom: 10px;
    }
    &__img{
        width:100%;
        height:400px;
        object-fit: cover;
    }
    &__info{
        padding:0 20px;
    }
    &__icon{
        width: 92px;
        height: 95px;
        background-color: #f9f9f9;
        padding-top: 15px;
    }
    &__section-title{
        font-size: 18px;
        color: #211919;
        margin:3px 0 0;
    }
    &__description{
        margin:10px 0;
        color: #211919;
    }
    &__list{
        list-style-type: none;
        list-style-image: url('/images/line.png');
        margin-top: 10px;
    }
    &__item-list{
        padding: 3px 0;
        color: #211919;
    }
}

@media(min-width:768px){
   .services{
       &__box{
           display: flex;
           flex-direction: row-reverse;
       }
       &__img{
           width:50%;
           height:auto;
       }
       &__box-reverse{
           display: flex;
           flex-direction: row;
       }
       &__icon{
           padding-top: 15px;
       }
   }
}
@media(min-width:1024px){
    .services{
        &__intro{
            padding-left: 50px;
        }
        &__img{
            height:550px;
        }
        &__icon{
            width: 90px;
            height: 105px;
        }
        &__info{
            width:25%;
            margin:70px auto 0;
        }
        &__section-title{
            font-size:30px;
        }
        &__description{
            font-size: 20px;
        }
        &__item-list{
            font-weight: bold;
            font-size:19px;
        }
    }
}