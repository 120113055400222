.terms{
    background-color: #f9f9f9;
    &__title{
        text-align: center;
        margin-left: 0;
        margin-top: 17%;
    }
    &__wrapper{
        padding:20px;
    }
    &__subtitle{
        font-family: NeoSansStd;
        margin:10px 0 0;
        font-size: 20px;
        line-height: 1.64;
        color: #330000;
        font-weight: bolder;
    }
    &__info{
        font-family: NeoSansStd;
        font-size: 18px;
        font-weight: 300;
        line-height: 1.56;
        color: #211919;
        margin:5px 0;
    }
    &__list{
        list-style-image: url('/images/line.png');
    }
    &__list-item{
        font-family: NeoSansStd;
        font-size: 18px;
        font-weight: 300;
        line-height: 1.56;
        color: #211919;
    }
    &__link{
        text-decoration: none;
        cursor: pointer;
    }
    @media(min-width:768px){
        &__wrapper{
            padding:20px 40px;
        }
        &__title{
            margin-top: 8%;
        }
    }
    @media(min-width:1024px){
        &__intro{
            padding:0;
        }
        &__wrapper{
            padding:20px 10%;
            width:70%;
        }

    }
}