.header{
    padding:15px 0 0;
    border-bottom: 0.1px solid #cccccc;
    border-opacity:0.5;

    &__logo-box{
        display: flex;
        padding-left:20px;
    }
    &__logo-prometeus{
        width:35px;
        height: 35px;
    }
    &__logo-name{
        color: #ffff;
        position: relative;
        bottom:10px;
      }
      &__logo-name-bold{
          font-weight: bolder;
      }
      &__menu{
          display: none;
      }
      &__mobile-menu-icon{
          width:30px;
          margin-right: 20px;
          position: relative;
          bottom: 10px;
          cursor: pointer;
      }
      &__mobile-header{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
      }

      &__menu-link{
          cursor: pointer;
          text-decoration: none;
          color: #ffff;
      }
}
@media(min-width:768px){
    .header{
        padding-left:30px;

    &__logo-prometeus{
        width:45px;
        height: 45px;
    }
}
}
@media(min-width:1024px){
    .header{
      padding:0 10%;

      &__logo-box {
        padding-left: 0;
      }

      &__logo-prometeus{
          width:50px;
          height:50px;
          padding-top: 20px;
      }
    &__logo-name{
        padding-top: 5px;
        letter-spacing: 0.2;
        top:10px;
        font-size: 18px;
    }
    &__header-container{
        display: flex;
        justify-content: space-between;
    }
    &__menu{
      display: flex;
      width: 65%;
      justify-content: flex-end;
    }
    &__menu-list{
        list-style-type: none;
        display: flex;
        justify-content: space-between;
        margin: 0;
    }
    &__menu-item{
        font-family: NeoSansStd;
        font-size: 16px;
        font-weight: bolder;
        padding:30px 20px 40px;
        color:#ffff;
    }
    &__menu-item:hover{
        background-color: #ff0000;
    }
    &__mobile-menu-icon{
        display: none;
    }
}
}
