button{
    border-style: solid;
    border-width: 1px;
    border-image-source: linear-gradient(to bottom, #3fa9f5, #127bc6);
    border-image-slice: 1;
    background-color: #3fa9f5;
    width:85px;
    height:30px;
    cursor:pointer;
}
.red-button{
    background-color:red;
    width: 65px;
    border:none;
}

.blue-button{
    background-color:#3fa9f5;
    width: 65px;
    border:none;
}

@media(min-width:1024px){
    button{
        width:100px;
    }
}
