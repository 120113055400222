.about{
    background-image: linear-gradient(to top, #f9f9f9, #ffffff);
    &__container{
        background-color: transparent;
        background-size: 89% 100%;
        background-repeat: repeat;
        background-image: linear-gradient(to right, #cccccc 1px, transparent 1px);
        background-position: 40.5%;
    }
    &__mission{
        padding:0 20px;
        height: 100%;
    }
    &__mission-wrapper{
        padding:0 10px;
    }
    &__about-img{
        width:100%;
        height: auto;
    }
    &__values{
        padding:10px 30px;
        box-sizing: border-box;
    }
    &__values-specific{
        width: 100%;
    }
    &__values-img{
        width:40px;
        height:40px;
        color:grey;
    }
    &__values-description{
        margin-top: 5px;
        color: #211919;
    }
    &__values-title{
        margin-top: 0;
        padding-top: 10px;
    }
    &__clients{
        padding:5px 30px;
        background-color: #ffff;
    }
    &__logos-container{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin:0 auto;
    }
    &__logo{
        margin:15px auto;
        cursor: pointer;
        width: 90px;
        height: 50px;
        object-fit: contain;
    }
    &__logo-link{
        width:50%;
    }
    &__logo-city-council{
        width: 65px;
        height: 70px;
    }
}
#map {
    width: 100%;
    height: 400px;
    background-color: grey;
  }
@media(min-width:768px){
    .about{
    &__container{
        background-size: 93% 100%;
        background-position: 28.5%;
    }
    &__mission-wrapper{
        padding:0 10px;
        height: 150px;
        width:80%;
    }
    &__about-img{
        height:300px;
        object-fit: cover;
    }

    &__values{
        display: flex;
        width:100%;
    }
    &__values-title-box{
        width:25%;
    }
    &__values-box{
        display: flex;
        width:70%;
        flex-wrap: wrap;
        padding-left: 30px;
    }
    &__values-specific{
        width:50%;
    }
    &__clients{
        display: flex;
        flex-direction: column;
    }
    &__clients-box{
        width:50%;
    }
    &__logos-container{
        justify-content: initial;
        width:100%;
    }
    &__logo{
        width: 95px;
    }
    &__logo-link{
        width:25%;
    }
    &__logo-city-council{
        width: 60px;
        height: 75px;
    }
}
}

@media(min-width:1024px){
     .about{
         width:100%;

        &__container{
            padding:0 10% 0;
            background-size: 20% 100%;
            background-position: 12.5%;

        }
        &__values{
            padding:30px 0 20px;
        }
        &__mission-wrapper{
            height:auto;
            padding: 0;
        }
        &__mission{
            padding: 5px 0 20px;
            background-size: 25% 120%;
            background-position: 2%;
        }
        &__about-img{
            height:530px;
            width:100%;
        }
        &__title-box{
            width:20%;
        }
        &__values-box{
            width:75%;
        }
        &__values-specific{
            width:40%;
            padding:15px 30px;
        }
        &__values-description{
            font-size: 18px;
        }
        &__clients{
            padding:10px 10% 40px;
            flex-direction: row;
        }
        &__clients-box{
            width:70%;
        }
        &__logos-container{
            flex-direction: row;
            position: relative;
            top: 70px;
            left: 80px;
        }
        &__logos-container{
            justify-content: initial;
            width:100%;
            position: relative;
            top:30px;
            right:40px;
        }
        &__logo{
            margin:0 60px;
            height:50px;
            width:110px;
        }
        &__logo-link{
            width:25%;
        }
        &__logo-city-council{
            width: 60px;
            height: 85px;
        }
    }
}
